import React from "react"
import { graphql } from "gatsby"

import { CustomPageProps } from "@utils/types"
import { PageInner } from "@components/secondaryPages/PageInner"
import ProductIcon from "@icons/secondaryPages/FlowersBig.svg"

const FlowersPage: React.FC<CustomPageProps> = ({
  data,
  pageContext,
  location,
}) => {
  // @ts-ignore
  const image = data.directus.items.flowers_page.photo.imageFile
  // @ts-ignore
  const content = data.directus.items.flowers_page.translations[0]

  return (
    <PageInner
      pageContext={pageContext}
      location={location}
      image={image}
      content={content}
      icon={<ProductIcon />}
    />
  )
}

export const query = graphql`
  query FlowersRuPage {
    directus {
      items {
        flowers_page {
          photo {
            id
            imageFile {
              childImageSharp {
                gatsbyImageData(width: 2600, placeholder: DOMINANT_COLOR)
              }
            }
          }
          translations(filter: { languages_code: { code: { _eq: "ru-RU" } } }) {
            title
            description
            subtitle
            content
          }
        }
      }
    }
  }
`

export default FlowersPage
